<template>
  <overlay-base :transition="false">
    <div :class="['d-flex', 'flex-column', { 'fill-height justify-center': $vuetify.display.smAndUp }]">
      <v-container class="overlay-mobile-aes">
        <v-row no-gutters>
          <v-col class="ma-auto" cols="12" md="6">
            <heading
              name="overlay-mobile-aes"
              center
              :title="$t('overlay.mobile_aes.title')"
              :subtitle="$t('overlay.mobile_aes.subtitle', { number: `+${mobileNumber}` })"
            />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col class="ma-auto" cols="12" md="4">
            <div align="center" class="center font-weight-bold text-skribbleu mb-6">
              {{ $t('overlay.mobile_aes.enter_sms_code') }}
            </div>
            <input-tan v-model="tan" @enter="confirm" />
            <div align="center" class="center mb-10">
              <strong>
                <i18n-t class="ma-0" keypath="overlay.mobile_aes.resend_sms_code.text" tag="small">
                  <template #link_text>
                    <span
                      class="overlay-mobile-aes__resend-code__link | text-decoration-underline text-primary font-weight-regular"
                      data-cy="requestNewCodeLink"
                      @click="requestNewTan"
                    >
                      {{ $t('overlay.mobile_aes.resend_sms_code.link_text') }}
                    </span>
                  </template>
                </i18n-t>
              </strong>
            </div>
            <v-btn
              size="x-large"
              color="info"
              block
              class="mb-4"
              data-cy="confirmTan"
              :class="$vuetify.display.xs ? 'order-first' : ''"
              :disabled="loadingStates.isConfirming || !isTanValid"
              :loading="loadingStates.isConfirming"
              @click="confirm"
            >
              {{ $t('global.confirm') }}
            </v-btn>
            <v-btn
              class="flat-link"
              size="x-large"
              variant="text"
              block
              :loading="loadingStates.isCancelling"
              :disabled="loadingStates.isCancelling"
              @click="cancel"
            >
              {{ $t('global.cancel') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </overlay-base>
</template>

<script lang="ts">
import type { PropType } from 'vue'

import Heading from '@/components/Heading.vue'
import InputTan from '@/components/inputs/TanInput.vue'
import OverlayBase from '@/components/overlays/OverlayBase.vue'

export default defineComponent({
  name: 'OverlayMobileAes',
  components: { InputTan, OverlayBase, Heading },
  props: {
    continuationData: {
      type: Object as PropType<{ challengeId: string }>,
      required: true,
    },
  },
  setup(props) {
    const userStore = useUserStore()
    const widgetStore = useWidgetStore()

    const { t } = useI18n()

    const { tanRepository } = useApi()

    const mobileNumber = computed(() => userStore.attributes?.mobile?.[0] ?? '')

    const challengeId = computed(() => props.continuationData.challengeId)

    const tan = ref('')

    const isTanValid = computed(() => /^\d+$/.test(tan.value) && tan.value.length === 6)

    const loadingStates = reactive({
      isCancelling: false,
      isConfirming: false,
    })

    const requestNewTan = async () => {
      await tanRepository.resend(challengeId.value)
      widgetStore.resetSnackbars()
      widgetStore.createSnackbar({
        message: t('overlay.mobile_aes.snackbar.new_code_sent_message'),
      })
    }

    const confirm = async () => {
      if (!isTanValid.value) return

      loadingStates.isConfirming = true

      const response = await tanRepository.verify(challengeId.value, tan.value)
      const status = response.status.toLowerCase()

      switch (status) {
        case 'unverified':
          widgetStore.createSnackbar({
            message: t('overlay.mobile_aes.snackbar.wrong_code_message'),
          })
          break
        case 'expired':
          tan.value = ''
          widgetStore.createSnackbar({
            message: t('overlay.mobile_aes.snackbar.resend_code_message'),
            duration: 30000,
            action: {
              text: t('overlay.mobile_aes.snackbar.resend_code_cta_text'),
              handler: requestNewTan,
            },
          })
          break
        case 'verified':
          widgetStore.activeOverlay = null
          break
        default:
          // TODO: Error case
          break
      }

      loadingStates.isConfirming = false
    }

    const cancel = async () => {
      loadingStates.isCancelling = true
      await tanRepository.cancelVerification(challengeId.value)
      loadingStates.isCancelling = false
    }

    return { mobileNumber, tan, isTanValid, loadingStates, confirm, cancel, requestNewTan }
  },
})
</script>

<style lang="sass">
.overlay-mobile-aes
  &__resend-code
    &__link
      cursor: pointer
</style>
